<template>
  <div class="about">
    <div class="main-text" v-html="detailsdata.content"></div>
  </div>
</template>

<script>
import { getdetails } from "@/api";
export default {
  name: "about",
  data() {
    return {
      detailsdata: {},
    };
  },
  methods: {
    async start() {
      await getdetails(4244).then((res) => {
        this.detailsdata = res.data;
      });
    },
  },
  mounted() {
    this.start();
  }
};
</script>

<style lang="less" scoped>
.main-text /deep/span{
  text-wrap: pretty !important;
}
.main-text {
        margin: 20px 0;
        line-height: 34px;
        
        /deep/img {
          margin: 0 auto!important;
          max-width: 100%!important;
        }
        /deep/p{
          color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
            font-family: "PINGFANG-REGULAR" !important;
            span{
                   color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
            font-family: "PINGFANG-REGULAR" !important;
            }
        }
        
      }
</style>